<!-- @format -->
<script>
	import { options, transmitter } from 'base_stores';
	import { fetchGet, fetchPost, fetchPut } from 'utils/fetch';
	import { dct, dctId, currentData, requiredFieldsFilled, commonParams } from '~/svelte/dcts/stores/stores';
	import { showModal } from '~/svelte/components/modal.svelte';
	import { isPresent } from 'utils/tools';
	import Case from 'case';
	import CounterpartyModal from '~/svelte/counterparties/_modal.svelte';
	import AutosaveSelect2Search from '~/svelte/components/ui/autosave_select2-search.svelte';

	const collator = new Intl.Collator();

	export let regionAttr = 'regionId';
	export let attr = 'correspondent_id';
	export let attrKind;
	export let attrKindName;
	export let placeholder = 'Выбрать адресата';

	let currentDctId;
	let correspondentKind;
	let correspondentKindNameV;
	let correspondentKindName;
	let forceSave = false;
	let newCorrespondentId;

	$: correspondentIdKind = Case.camel(attr);
	$: regionId = ($currentData && $currentData[Case.camel(regionAttr)]) || 'all';
	$: currentCorrespondentId = $currentData && $currentData[correspondentIdKind];
	$: correspondentOptions = correspondentKind && $options && $options.correspondent && $options.correspondent[correspondentKind];
	$: currentCorrespondentOptions = (correspondentOptions && correspondentOptions[regionId]) || [];
	$: if (newCorrespondentId) {
		currentCorrespondentId = newCorrespondentId;
		newCorrespondentId = null;
	}
	$: value = currentCorrespondentOptions && setValue(currentCorrespondentId);
	$: isBankOrCreditorOrCounterpartyOrKdl = ['bank', 'creditor', 'counterparty', 'kdl', 'cryptoex', 'employer'].includes(correspondentKind);
	$: disabled = $dct.disabled_fields && $dct.disabled_fields.includes('correspondentId');

	const setValue = id => {
		return (currentCorrespondentOptions && currentCorrespondentOptions.find(option => parseInt(option.id) === parseInt(id))) || { id: null };
	};

	$: if (
		regionId !== 'all' &&
		value &&
		value.id &&
		isPresent(currentCorrespondentOptions) &&
		!currentCorrespondentOptions.find(option => parseInt(option.id) === parseInt(currentCorrespondentId))
	) {
		value = { id: null };
		forceSave = true;
	}

	$: if (correspondentKind || regionId) {
		refreshCorrespondentOptions();
	}

	$: if (($dct && currentDctId !== $dctId) || $currentData.correspondentKind) {
		correspondentKind = attrKind || $dct.correspondent_kind || $currentData.correspondentKind;
		correspondentKindName = correspondentKind == 'kdl' ? 'КДЛ' : attrKindName || $dct.correspondent_kind_name || 'Корреспондент';
		correspondentKindNameV = $transmitter.correspondentKinds?.find(c => c.code == correspondentKind)?.declensions?.v;
		currentDctId = $dctId;
	}

	$: if ($dct.form_fields.find(field => RegExp(`^${correspondentIdKind}`).test(field))) {
		$requiredFieldsFilled[correspondentIdKind] = correspondentId.value && correspondentId.value.id;
	}

	const blockHandleChange = value => value == 'new';

	const handleBlocking = () => {
		showModal('form-counterparty-modal');
	};

	const addCorrespondentOption = async text => {
		const params = { text, correspondent_kind: correspondentKind, region_id: regionId };
		const response = await fetchPost('/api/private/addressees/create_and_return_option', params);
		const option = response.option;

		$transmitter.dct.substitutions.correspondent_id = option.id;

		let regionOptions = [...$options.correspondent[correspondentKind][regionId], option];
		regionOptions = regionOptions.map(opt => ({ ...opt, count: regionOptions.filter(rOpt => rOpt.text === opt.text).length }));
		regionOptions = [
			...regionOptions.filter(opt => opt.user_id && opt.count === 1).sort((a, b) => (a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1)),
			...regionOptions.filter(opt => (opt.user_id && opt.count > 1) || !opt.user_id).sort((a, b) => (a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1)),
		];

		$options.correspondent[correspondentKind][regionId] = regionOptions;
		if (regionId !== 'all') {
			$options.correspondent[correspondentKind]['all'] = regionOptions;
		}

		return option;
	};

	const refreshCorrespondentOptions = () => {
		if (!correspondentKind || ['addressee', 'court_usual'].includes(correspondentKind)) {
			return;
		}

		let url = '/api/private/counterparties/get_options_for_roles_dcts';
		let params = {};

		if (correspondentKind == 'counterparty') {
			params.roles = 'all';
		} else if (['bank', 'creditor', 'kdl', 'debtor_participant', 'worker', 'cryptoex', 'employer'].includes(correspondentKind)) {
			params.roles = [correspondentKind];
		} else if (correspondentKind) {
			url = '/api/private/organizations/options';
			params.region_id = ['court', 'bki', 'rsa', 'favt', 'fsis'].includes(correspondentKind) || regionId == 'all' ? null : regionId;
			params.correspondent_kind = correspondentKind;
		}

		fetchGet(url, params).then(
			response =>
				($options.correspondent = {
					...$options.correspondent,
					[correspondentKind]: { [regionId]: response.options.sort((a, b) => collator.compare(a.text, b.text)) },
				}),
		);
	};

	const setCorrespondentOption = counterparty => {
		if (
			$options.correspondent[correspondentKind][regionId] &&
			!$options.correspondent[correspondentKind][regionId].find(option => option.id == counterparty.id)
		) {
			newCorrespondentId = counterparty.contractor_id + '/' + counterparty.contractor_type;
			$options.correspondent[correspondentKind][regionId] = [
				...$options.correspondent[correspondentKind][regionId],
				{ id: newCorrespondentId, text: counterparty.name, type: counterparty.contractor_type },
			];
		}
	};

	const setCorrespondent = async e => {
		const response = await fetchGet('/api/private/counterparties', { filters: { roles: { only: correspondentKind } } });
		$transmitter.counterparties = response.counterparties;

		let newCounterparty = $transmitter.counterparties.find(c => String(c.id) === String(e.detail));

		if (isPresent(newCounterparty)) {
			setCorrespondentOption(newCounterparty);
			forceSave = true;
		} else {
			newCorrespondentId = null;
		}
	};

	const checkSuitabilityCounterparty = item => {
		if (correspondentKind == 'counterparty') {
			return true;
		}
		let correspondent = currentCorrespondentOptions.find(opt => opt.id == item.id);
		return isPresent(correspondent?.roles) ? correspondent.roles.includes(correspondentKind) : true;
	};

	const actionInUnsuitabilityCounterparty = async item => {
		let correspondent = currentCorrespondentOptions.find(opt => opt.id == item.id);
		let roles = [...correspondent.roles, correspondentKind];
		await fetchPut(`/api/private/counterparties/${correspondent.counterparty_id}`, { roles: roles });
		currentCorrespondentOptions.find(opt => opt.id == item.id).roles = roles;
	};

	$: correspondentId = {
		...$commonParams,
		disabled: $commonParams.disabled || disabled || $dct.status == 'approved',
		required: true,
		attribute: attr,
		label: correspondentKindName,
		checkSuitability: checkSuitabilityCounterparty,
		actionInUnsuitability: actionInUnsuitabilityCounterparty,
		value: value,
		options: isBankOrCreditorOrCounterpartyOrKdl
			? [{ id: 'new', text: `Добавить ${correspondentKindNameV}` }, ...currentCorrespondentOptions]
			: currentCorrespondentOptions,
		placeholder: placeholder || `Выбрать ${correspondentKindNameV}`,
		addOption: isBankOrCreditorOrCounterpartyOrKdl ? null : addCorrespondentOption,
	};
</script>

<AutosaveSelect2Search {...correspondentId} {blockHandleChange} {handleBlocking} bind:forceSave on:update />
<CounterpartyModal fixedRoles={[correspondentKind]} index="1" on:create={setCorrespondent} />
